@import './../../assets/styles/variables.module';
@import './../../assets/styles/palette.module';
// khi width screen dưới 500px thì áp dụng css này
@media (min-width: 300px) {
    .ProductVariant {
        min-height: 90vh;
        padding: 0 0.5rem;
        box-sizing: border-box;
        .breadcums {
            display: flex;
            flex-direction: row; //row|row-reverse|column|column-reverse
            justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|
            align-items: center; //stretch|center|flex-start|flex-end
            flex-wrap: wrap; //|wrap|wrap-reverse
            padding: 0;
            margin: 2rem 0 0 0;
            p {
                margin: 0 0.5rem 0 0;
                cursor: pointer;
                &:hover {
                   color: #0d6efd;
                   font-weight: bold;
                }
            }
            .arrow {
                color: $color-red-ruby;
                font-weight: bold;
            }
        }
        .title {
            font-size: $font-size-heading;
            font-weight: bold;
            text-align: center;
            margin: 0.5rem 0 0 0;
        }
        .container {
            display: grid;
            grid-template-columns: 50% 50%;
            justify-content: space-between; // flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
            align-content: start; // start || center || end || stretch || space-around || space-between || space-evenly
            align-items: start; // this aligns items to the top (start) of the container
            text-align: center;
            margin: 1rem 0 0 0;
            .left {
                overflow: auto;
                height: 90vh;
                overflow-x: hidden;
                padding: 0.5rem 0.5rem;
                display: grid;
                grid-template-columns: 1fr;
                justify-content: space-between;
                align-content: start;
                align-items: flex-start; // Use 'flex-start' instead of 'top'
                text-align: center;
                border-right: 1px dotted rgba(184, 184, 184, 0.893);
                direction: rtl; // Add this line to move the scrollbar to the left
                align-self: start;
                .productVariant {
                    margin: 0 0 0.5rem 0;
                    padding: 0.5rem;
                    box-sizing: border-box;
                    box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
                    cursor: pointer;
                    border-radius: 0.5rem;
                    transition: 0.2s;
                    
                    &:hover {
                        box-shadow: 0 0 10px rgba(252, 248, 0, 0.893);
                    }
                    
                    .productImage {
                        img {
                            width: 80%; /* Set width to 80% of its container */
                            height: auto; /* Set a fixed height */
                            object-fit: cover; /* Ensure the image covers the area, maintaining its aspect ratio */
                            border-radius: 0.5rem;
                        }
                    }
                    
                    .productInfo {
                        .productName {
                            font-size: $font-size-paragraph;
                        }
                    }
                    
                    &.selected {
                        background-color: $color-silver; // Change this to your desired color
                    }
                }
            }
            
            .right {
                .details {
                    display: grid;
                    grid-template-columns: 1fr;
                    justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
                    align-content: start; // start || center || end || stretch || space-around || space-between || space-evenly
                    align-items: top;//top, center, end
                    align-self: top;
                    text-align: center;
                    padding: 1rem 0 0 0;
                    .row {
                        padding: 1rem 0 1rem 2rem;
                        text-align: left;
                        transition: 0.2s;
                        margin: 0 0 0 0;
                        &:hover {
                           color: #0d6efd;
                        }
                        .key {
                            font-size: $font-size-paragraph;
                            font-weight: bold;
                        }
                        .value {
                            padding: 0 0 0 1rem;
                        }
                    }
                    .note {
                        margin: 5rem 0 0 0;
                        font-weight: bold;
                        padding: 0 1rem;
                    }
                    .skeleton {
                        // padding: 0 0 0 2rem;
                        width: 70%;
                        margin: 1rem auto;
                        .indent {
                            padding: 0 0 0 2rem;
                        }
                    }
                    .imageVariantContainer {
                        display: flex;
                        flex-direction: row; //row|row-reverse|column|column-reverse
                        justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                        align-items: center; //stretch|center|flex-start|flex-end
                        flex-wrap: wrap; //|wrap|wrap-reverse
                        gap: 1rem;
                        // border: 1px solid red;
                        padding: 0 0 1rem 0.5rem;
                        img {
                            width: 50px;
                            height: auto;
                            border-radius: 0.5rem;
                            box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .backShadow {
            display: none;
            opacity: 0;
            position: fixed;
            z-index: 1;
            width: 100%;
            height: calc(100% - 60px);
            top: 50px;
            background-color: rgba(255, 255, 255, 0.8);
            backdrop-filter: blur(3px);
            // cho nổi lên, bao trùm màn hình, làm mờ end
            animation: fade-in-out 0.5sn ease;
        }
    
        .dialogImg {
            position: absolute;
            top: 10%;
            left: 15%;
            border-radius: 1rem;
            box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
            z-index: 10;
            background: white;
            opacity: 0;
            display: none;
            animation: fade-in-out 0s ease;
            border-radius: 1rem;
            box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
            padding: 1rem 1rem;
            width: 70%;
            img {
                width: 100%;
            }
        }
        @keyframes fade-in-out {
            0% {
            opacity: 0;
            display: none;
            }
            50% {
            opacity: 0;
            display: none;
            }
            100% {
            opacity: 1;
            display: block;
            }
        }
        .appear {
            opacity: 1;
            display: block;
        }
    }
}
    
@media (min-width: 600px) {
    .ProductVariant{
        .container{
            .left{
                padding: 0.5rem 1rem;
                .productVariant{
                    margin: 0 0 1rem 0;
                }
            }
        }
    }
}

@media (min-width: 1000px) {
    .ProductVariant {
        min-height: 90vh;
        padding: 0 0.5rem;
        box-sizing: border-box;
        .breadcums {
            margin: 0.5rem 0 0 0;
        }
        .title {
            font-size: $font-size-heading;
            font-weight: bold;
            text-align: center;
            margin: 0rem 0 0 0;
        }
        .container {
            display: grid;
            grid-template-columns: 50% 50%;
            justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
            align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
            align-items: top;//top, center, end
            align-self: center;
            text-align: center;
            margin: 1rem 0 0 0;
            .left {
                overflow: auto;
                height: 90vh;
                overflow-x: hidden;
                padding: 0.5rem 1rem;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 1rem;
                justify-content: space-between;
                align-content: start;
                align-items: flex-start; // Use 'flex-start' instead of 'top'
                align-self: center;
                text-align: center;
                border-right: 1px dotted rgba(184, 184, 184, 0.893);
                direction: rtl; // Add this line to move the scrollbar to the left
            
                .productVariant {
                    margin: 0 0 0 0;
                    padding: 0.5rem;
                    box-sizing: border-box;
                    box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
                    cursor: pointer;
                    border-radius: 0.5rem;
                    transition: 0.2s;
                    
                    &:hover {
                        box-shadow: 0 0 10px rgba(252, 248, 0, 0.893);
                    }
                    
                    .productImage {
                        img {
                            width: 80%;
                        }
                    }
                    
                    .productInfo {
                        .productName {
                            font-size: $font-size-paragraph;
                        }
                    }
                    
                    &.selected {
                        background-color: $color-silver; // Change this to your desired color
                    }
                }
            }
            
            .right {
                .details {
                    display: grid;
                    grid-template-columns: 1fr;
                    justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
                    align-content: start; // start || center || end || stretch || space-around || space-between || space-evenly
                    align-items: top;//top, center, end
                    align-self: top;
                    text-align: center;
                    padding: 1rem 0 0 0;
                    .row {
                        padding: 1rem 0 1rem 2rem;
                        text-align: left;
                        transition: 0.2s;
                        margin: 0 0 0 0;
                        &:hover {
                           color: #0d6efd;
                        }
                        .key {
                            font-size: $font-size-paragraph;
                            font-weight: bold;
                        }
                        .value {
                            padding: 0 0 0 1rem;
                        }
                    }
                }
            }
        }
        .dialogImg {
            left: 20%;
            width: 50%;
         
        }
    }
}