@import './../../assets/styles/variables.module';
@import './../../assets/styles/palette.module';
// khi width screen trên 300px thì áp dụng css này
@media (min-width: 300px) {
    .Gallery{
        min-height: 80vh;
        .title {
            font-size: $font-size-title;
            font-weight: bold;
            text-align: center;
            margin: 2rem 0;
        }
        .container {
            display: grid;
            grid-template-columns: 1fr;
            justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
            align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
            align-items: top;//, center, end
            align-self: center;
            text-align: center;
            margin: 0 0 1rem 0;
            .left {
                .row {
                    display: flex;
                    flex-direction: row; //row|row-reverse|column|column-reverse
                    justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                    align-items: center; //stretch|center|flex-start|flex-end
                    flex-wrap: nowrap; //|wrap|wrap-reverse
                    .btnFilter {
                        padding: 0.5rem 1rem;
                        background: #0d6efd;
                        border-radius: 0.5rem;
                        outline: none;
                        border: none;
                        box-shadow: 0 0 10px rgba(149, 149, 149, 0.893);
                        color: white;
                        cursor: pointer;
                        &:active{
                           box-shadow: none;
                        }
                    }
                 
                }
                .filterResult {
                    display: flex;
                    flex-direction: row; //row|row-reverse|column|column-reverse
                    justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                    align-items: center; //stretch|center|flex-start|flex-end
                    flex-wrap: wrap; //|wrap|wrap-reverse
                    .topRow {
                        display: flex;
                        flex-direction: row; //row|row-reverse|column|column-reverse
                        justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                        align-items: center; //stretch|center|flex-start|flex-end
                        flex-wrap: nowrap; //|wrap|wrap-reverse
                        width: 100%;
                        padding: 0 1rem 0 1rem;
                        margin: 0 0 1rem 0;
                        .btnIcon {
                            display: flex;
                            flex-direction: row; //row|row-reverse|column|column-reverse
                            justify-content: space-evenly; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                            align-items: center; //stretch|center|flex-start|flex-end
                            flex-wrap: nowrap; //|wrap|wrap-reverse
                            background: $color-light-gray;
                            border-radius: 0.5rem;
                            padding: 0.15rem 1rem;
                            &:hover{
                                box-shadow: 0 0 10px rgba(206, 206, 206, 0.893);
                            }
                            &:active{
                                box-shadow: 0 0 10px rgba(97, 97, 97, 0.893);
                             }
                            span {
                                display: block;
                                margin: 0.2rem 0.5rem 0 0;
                                color: white;
                            }
                            button {
                                padding: 0;
                                background: none;
                                border-radius: 0.5rem;
                                outline: none;
                                border: none;
                                box-shadow: 0 0 10px rgba(149, 149, 149, 0.893);
                                color: white;
                                font-size: $font-size-paragraph;
                                cursor: pointer;
                            }
                        }
                        .count {
                            span {
                                font-weight: bold;
                            }
                        }
                    }
                    .categoryListWrapper {
                        display: flex;
                        display: none;
                        flex-direction: row; //row|row-reverse|column|column-reverse
                        justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                        align-items: center; //stretch|center|flex-start|flex-end
                        flex-wrap: wrap; //|wrap|wrap-reverse
                        .box {
                            display: flex;
                            flex-direction: row; //row|row-reverse|column|column-reverse
                            justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                            align-items: center; //stretch|center|flex-start|flex-end
                            flex-wrap: nowrap; //|wrap|wrap-reverse
                            box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
                            border-radius: 0.5rem;
                            margin: 0 0 0.5rem 0.5rem;
                            padding: 0 0.5rem;
                            .item {
                                padding: 0 0 0 0;
                                margin: 0 0 0 0;
                            }
                            span {
                                cursor: pointer;
                                margin: 0.3rem 0 0 0;
                                &:hover {
                                   color: #0d6efd;
                                }
                            }
                        }
                    }
                }
           
                .filterWrapper {
                    position: absolute;
                    top: 6rem;
                    // left: 2rem;
                    left: 15%;
                    width: 70%;
                    margin: 0 auto;
                    box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
                    border-radius: 0.5rem;
                    z-index: 10;
                    padding: 1rem;
                    box-sizing: border-box;
                    background-color: white;
                    .heading {
                        font-size: $font-size-heading;
                        font-weight: bold;
                        margin: 0 0 1rem 0;
                    }
                    .row {
                        padding: 0 0 0 2rem;
                        display: grid;
                        grid-template-columns: 20% 80%;
                        gap: 1;
                        justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
                        align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
                        align-items: center;//top, center, end
                        align-self: center;
                        text-align: center;
                        // transition: 0.5s;
                        // border: 1px solid blue;
                        &:hover {
                            color: $color-primary;
                            font-weight: bold;
                        }
                        label {
                            text-align: left;
                            padding: 0.2rem 0;
                        }
                    }
                 
                }
                .filterListWeb {
                    display: none;
                }
            }
            .right {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 1rem;
                justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
                align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
                align-items: center;//top, center, end
                align-self: center;
                text-align: center;
                box-sizing: border-box;
                padding: 0 1rem;
                .frame {
                    padding: 0rem;
                    box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
                    border-radius: 0.5rem; /* Optional rounded corners */
                    cursor: pointer;
                    &:hover {
                        box-shadow: 0 0 10px $color-primary;
                    }
                    .innerFrame {
                        border-radius: 0.5rem; /* Optional rounded corners */
                        box-shadow: 0 0 10px inset rgba(192, 190, 190, 0.893);
                        padding: 0.8rem;
                        // border: 1px solid blue;
                        img {
                            width: 100%;
                        }
                    }
                }
                .view360 {
                    padding: 0rem;
                    box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
                    border-radius: 0.5rem; /* Optional rounded corners */
                    cursor: pointer;
                    &:hover {
                        box-shadow: 0 0 10px $color-primary;
                    }
                    .innerFrame {
                        border-radius: 0.5rem; /* Optional rounded corners */
                        box-shadow: 0 0 10px inset rgba(192, 190, 190, 0.893);
                        padding: 0.8rem;
                        // border: 1px solid blue;
                        position: relative;
                        .preview {
                            width: 100%;
                        }
                        .iconView360 {
                            position: absolute;
                            top: 2rem;
                            left: 2rem;
                            width: 2rem;
                        }
                    }
                }
            }
        }
        .dialogImage {
            position: absolute;
            top: 5%;
            left: 10%;
            z-index: 100; //dialogImage
            width: 80%;
            .frame {
                padding: 0rem;
                box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
                border-radius: 0.5rem; /* Optional rounded corners */
                background-color: rgb(255, 255, 255);
                .innerFrame {
                    border-radius: 0.5rem; /* Optional rounded corners */
                    box-shadow: 0 0 10px inset rgba(192, 190, 190, 0.893);
                    padding: 0.8rem;
                    // border: 1px solid blue;
                    img {
                        width: 100%;
                    }
                }
            }
        }
        .backShadow {
            display: none;
            opacity: 0;
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 2; /* Ensure .backShadow is behind .subMenu */
            background-color: rgba(255, 255, 255, 0.63);
            backdrop-filter: blur(1px);
            animation: fade-in-out 0.5s ease;
            border: 1px solid green;
        }
        
        .appear {
            opacity: 1;
            display: block;
        }
    }
}
@media (min-width: 700px) {
    .Gallery{
        .container {
            grid-template-columns: 30% 70%;
            .left {
                padding: 0 0 0 1rem;
                .filterResult {
                    flex-direction: column;
                    justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                    align-items: flex-start; //stretch|center|flex-start|flex-end
                    .topRow {
                        .btnIcon {
                            // margin: 0 0 0.5rem 0.5rem;
                            display: none;
                        }

                    }
                    .categoryListWrapper {
                        display: flex;
                        flex-direction: row; //row|row-reverse|column|column-reverse
                        justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                        align-items: center; //stretch|center|flex-start|flex-end
                        flex-wrap: wrap; //|wrap|wrap-reverse
                        padding: 0 0 0 1rem;
                        margin: 1rem 0 0 0;
                    }
                }
                .count {
                    display: none;
                }
                .filterWrapper {
                    display: none;
                }
                .filterListWeb {
                    display: block;
                    padding: 0 0 0 0;
                    margin: 0 0 0 0;
                    .topWrapper {
                        display: flex;
                        flex-direction: row; //row|row-reverse|column|column-reverse
                        justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                        align-items: center; //stretch|center|flex-start|flex-end
                        flex-wrap: nowrap; //|wrap|wrap-reverse
                        // border: 1px solid blue;
                        p {
                            padding: 0 0 0 0;
                            margin: 0 0 0 0;
                        }
                        .heading {
                            font-size: $font-size-heading;
                            font-weight: bold;
                            margin: 0 0 0 0;
                        }
                        .countWebView {
                            span {
                                font-weight: bold;
                            }
                        }
                    }
                    .row {
                        padding: 0 0 0 0;
                        margin: 0 0 0 0;
                        padding: 0 0 0 0rem;
                        display: grid;
                        grid-template-columns: 20% 80%;
                        gap: 1;
                        justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
                        align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
                        align-items: center;//top, center, end
                        align-self: center;
                        text-align: center;
                        cursor: pointer;
                        // border: 1px solid blue;
                        &:hover {
                            color: $color-primary;
                            font-weight: bold;
                        }
                        label {
                            text-align: left;
                            padding: 0.2rem 0;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1000px) {
    .Gallery{
        .container {
            grid-template-columns: 25% 75%;
        }
    }
}
@media (min-width: 1300px) {
    .Gallery{
        .container {
            grid-template-columns: 20% 80%;
            align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
            align-items: top;//, center, end
            align-self: top;
            text-align: top;
            .right {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }
}