.outOfView {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .inView {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
    div {
        div {
            p:nth-child(1) {
                opacity: 1;
                transform: translateY(-10px);
                transition: opacity 5s ease-out, transform 5s ease-out;
            }
            p:nth-child(2) {
                opacity: 1;
                transform: translateY(-25px);
                transition: opacity 5s ease-out, transform 5s ease-out;
            }
            p:nth-child(3) {
                opacity: 1;
                transform: translateY(-30px);
                transition: opacity 5s ease-out, transform 5s ease-out;
            }
        }
    }
}
