@import './../../assets/styles/variables.module';
@import './../../assets/styles/palette.module';

.UpdatePhone {
    height: 60vh;
    .container {
        width: 80%;
        margin: 5rem auto;
        box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
        cursor: pointer;
        border-radius: 0.5rem;
        padding: 1rem 2rem 1rem 1rem;
        box-sizing: border-box;
        .title {
            font-size: $font-size-heading;
            font-weight: bold;
            text-align: center;
            margin: 1rem 0 2rem 0;
        }
        form {
            .row {
                margin: 0.5rem 0 1rem 0;
                display: flex;
                flex-direction: column; //row|row-reverse|column|column-reverse
                justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                align-items: center; //stretch|center|flex-start|flex-end
                flex-wrap: nowrap; //|wrap|wrap-reverse
                width: 100%;
                padding: 0 0 0 0;
                box-sizing: border-box;
                label {
                    font-size: $font-size-subheading;
                    text-align: left;
                    width: 100%;
                    margin: 0 0 0.5rem 0;
                    display: flex;
                    flex-direction: row; //row|row-reverse|column|column-reverse
                    justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                    align-items: center; //stretch|center|flex-start|flex-end
                    flex-wrap: nowrap; //|wrap|wrap-reverse
                    font-weight: bold;
                }
                .inputWrapper{
                    display: flex;
                    flex-direction: row; //row|row-reverse|column|column-reverse
                    justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                    align-items: center; //stretch|center|flex-start|flex-end
                    flex-wrap: nowrap; //|wrap|wrap-reverse
                    border: 1px solid #ccc;
                    border-radius: 0.5rem;
                    padding: 0.5rem 1rem;
                    width: 100%;
                    box-sizing: border-box;
                    input {
                        border: none;
                        outline: none;
                        font-size: $font-size-paragraph;
                        box-sizing: border-box;
                        width: 100%;
                    }
                    .eye {
                        color: $color-gray;
                    }
                }
        
            }
            .rowHorizontal {
                margin: 0.5rem 0 2rem 0;
                display: flex;
                flex-direction: row; //row|row-reverse|column|column-reverse
                justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                align-items: center; //stretch|center|flex-start|flex-end
                flex-wrap: nowrap; //|wrap|wrap-reverse
                width: 100%;
                padding: 0 0 0 0;
                box-sizing: border-box;
                label {
                    font-size: $font-size-subheading;
                    text-align: left;
                    margin: 0 1rem 0 0;
                    display: flex;
                    flex-direction: row; //row|row-reverse|column|column-reverse
                    justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                    align-items: center; //stretch|center|flex-start|flex-end
                    flex-wrap: nowrap; //|wrap|wrap-reverse
                    font-weight: bold;
                }
                .phone {
                    text-align: left;
                    padding: 0 0 0 0;
                    margin: 0 0 0 0;
                    font-size: $font-size-subheading;
                }
            }
            .error {
                color: $color-red-ruby;
                text-align: left;
            }
            .btnWrapper {
                width: 70%;
                margin: 1rem auto;
                display: flex;
                flex-direction: row; //row|row-reverse|column|column-reverse
                justify-content: space-evenly; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                align-items: center; //stretch|center|flex-start|flex-end
                flex-wrap: nowrap; //|wrap|wrap-reverse
             }
        }

    }
}

// khi width screen trên 1000px thì áp dụng css này
@media (min-width: 1000px) {
    .UpdatePhone {
        width: 100%;
        height: 60vh;
        .container {
            width: 45%;
            margin: 5rem auto;
            box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
            cursor: pointer;
            border-radius: 0.5rem;
            padding: 1rem 3rem 1rem 3rem;
            box-sizing: border-box;
            .title {
                font-size: $font-size-heading;
                font-weight: bold;
                text-align: center;
                margin: 1rem 0 2rem 0;
            }
            form {
                .row {
                    margin: 0.5rem 0;
                    display: grid;
                    grid-template-columns: 50% 50%;
                    justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
                    align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
                    align-items: center;//top, center, end
                    align-self: center;
                    text-align: center;
                    label {
                        font-size: $font-size-subheading;
                        text-align: left;
                        width: 100%;
                        margin: 0 0 0.5rem 0;
                    }
                    .inputWrapper{
                        display: flex;
                        flex-direction: row; //row|row-reverse|column|column-reverse
                        justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                        align-items: center; //stretch|center|flex-start|flex-end
                        flex-wrap: nowrap; //|wrap|wrap-reverse
                        border: 1px solid #ccc;
                        border-radius: 0.5rem;
                        padding: 0.5rem 1rem;
                        input {
                            border: none;
                            outline: none;
                            font-size: $font-size-paragraph;
                            width: 100%;
                            box-sizing: border-box;
                        }
                        .eye {
                            color: $color-gray;
                        }
                    }
                }
                .rowHorizontal {
                    margin: 0.5rem 0 2rem 0;
                    display: grid;
                    grid-template-columns: 50% 50%;
                    justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
                    align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
                    align-items: center;//top, center, end
                    align-self: center;
                    text-align: center;
                    label {
                        font-size: $font-size-subheading;
                        text-align: left;
                        margin: 0 1rem 0 0;
                        display: flex;
                        flex-direction: row; //row|row-reverse|column|column-reverse
                        justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                        align-items: center; //stretch|center|flex-start|flex-end
                        flex-wrap: nowrap; //|wrap|wrap-reverse
                        font-weight: bold;
                    }
                    .phone {
                        text-align: left;
                        padding: 0 0 0 0;
                        margin: 0 0 0 0;
                        font-size: $font-size-subheading;
                    }
                }
                .error {
                    color: $color-red-ruby;
                    text-align: left;
                }
                .btnWrapper {
                    width: 70%;
                    margin: 1rem auto;
                    display: flex;
                    flex-direction: row; //row|row-reverse|column|column-reverse
                    justify-content: space-evenly; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                    align-items: center; //stretch|center|flex-start|flex-end
                    flex-wrap: nowrap; //|wrap|wrap-reverse
                 }
            }
        }
    }
}
