// @import './../../../assets/styles/font.css';
@import './../../../assets/styles/variables.module';
@import './../../../assets/styles/palette.module';

@media (min-width: 300px) {
    .GuestMenuBar {
        .topMenuBar{
            display: none;
        }
        .sideBar{
            display: block;
        }
        .subMenu {
            display: none;
        }
        .rightControl {
            position: fixed;
            right: 1rem;
            bottom: 3rem;
            z-index: 1000; // rightControl
        }
    }
}
@media (min-width: 1100px) {
    .GuestMenuBar {
        // border: 1px solid blue;
        .topMenuBar {
            // border: 1px solid blue;
            background: url(../../../assets/images/background/dark_green.jpg) repeat top center;
            // border: 1px solid red;
            font-family: 'Roboto-Regular';
            display: grid;
            grid-template-columns: 30% 40% 30%;
            justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
            align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
            align-items: center;//top, center, end
            align-self: center;
            text-align: center;
            padding: 0 0 0 0;
            margin: 0 0 0 0;
            box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
            z-index: 1;
            position: relative;
            top: 0;
            .logoWrapper {
                text-align: left;
                padding: 0 0 0 3rem;
                img {
                    width: 3rem;
                    padding: 0 0 0 0;
                    margin: 0 0 0 0;
                }
            }
            .menuItemWrapper {
                font-size: $font-size-subheading;
                display: flex;
                flex-direction: row; //row|row-reverse|column|column-reverse
                justify-content: space-evenly; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                align-items: center; //stretch|center|flex-start|flex-end
                flex-wrap: nowrap; //|wrap|wrap-reverse

                .menuItem {
                    cursor: pointer;
                    span {
                        padding: 0.5rem 0;
                        margin: 0 0 0 0;
                        color: white;
                        font-weight: bold;
                        transition: 0.2s;
                        // border: 1px solid red;
                        &:hover {
                            color: #0d6efd; // Change color on hover
                        }
                    }
                    .subMenu {
                        width: 100%;
                        height: 32rem;
                        background-size: cover;
                        position: absolute; /* Ensure proper positioning */
                        z-index: 3; //subMenu
                        border: 4px solid white;
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
                        align-content: start; // start || center || end || stretch || space-around || space-between || space-evenly
                        align-items: end;//top, center, end
                        align-self: end;
                        text-align: end;
                        padding: 2rem 0 0 0;
                        left: 0;
                        .box {
                            display: flex;
                            flex-direction: column; //row|row-reverse|column|column-reverse
                            justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                            align-items: center; //stretch|center|flex-start|flex-end
                            flex-wrap: nowrap; //|wrap|wrap-reverse
                            cursor: pointer;
                            img {
                                height: auto; /* Maintain aspect ratio */
                                transition: transform 0.3s ease-in-out; /* Smooth transition for scaling */
                                width: 20rem;
                                // max-height: 10rem;
                
                                &:hover {
                                   transform: scale(1.1);
                                }
                            }
                            .door {
                                width: 5rem;
                            }
                            span {
                                color: white;
                                font-size: $font-size-subheading;
                                font-weight: bold;
                                margin: 1rem 0 0 0;
                            }
                        }
                    }
                    .subMenu_product {
                        background: 
                            linear-gradient(to bottom, rgba(54, 50, 46, 1) 0%, rgba(54, 50, 46, 0) 10%, rgba(54, 50, 46, 0) 100%),
                            url(../../../assets/images/background/bg_sub_menu_product.jpg) no-repeat top center;
                        position: absolute; // Position remains the same
                        top: 40px; // Adjust according to your layout needs
                        left: 0;
                        right: 0;
                        opacity: 0; // Initially hidden
                        visibility: hidden; // Prevent interaction
                        transition: opacity 1s ease, visibility 0s 0.3s; // No transform, just fade in/out
                    }
                    .subMenu_gallery {
                        background: 
                            linear-gradient(to bottom, rgba(54, 50, 46, 1) 0%, rgba(54, 50, 46, 0) 10%, rgba(54, 50, 46, 0) 100%), 
                            url(../../../assets/images/background/bg_sub_menu_gallery.jpg) no-repeat top center;
                        display: flex;
                        flex-direction: row; //row|row-reverse|column|column-reverse
                        justify-content: space-evenly; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                        align-items: center; //stretch|center|flex-start|flex-end
                        flex-wrap: nowrap; //|wrap|wrap-reverse
                        // display: none;
                        position: absolute; // Position remains the same
                        top: 40px; // Adjust according to your layout needs
                        left: 0;
                        right: 0;
                        opacity: 0; // Initially hidden
                        visibility: hidden; // Prevent interaction
                        transition: opacity 1s ease, visibility 0s 0.3s; // No transform, just fade in/out
                        .box {
                            width: 25%;
                            img {
                                border: 3px solid white;
                                width: 100%;
                                &:hover {
                                   transform: scale(1.1);
                                   filter: brightness(50%);
                                   -webkit-transition: all 4s cubic-bezier(.23,1,.32,1);
                                   transition: all 4s cubic-bezier(.23,1,.32,1);
                                }
                            }
                        }
                    }
                    
                }
                .Products {
                    // position: relative; // Keep it relative to .Products container
                
                    &:hover {
                        .subMenu_product {
                            opacity: 1; // Fully visible
                            visibility: visible; // Make it visible
                            transition: opacity 1s ease, visibility 0.5s; // Remove the delay for visibility
                        }
                    }
                
                    &:not(:hover) {
                        .subMenu_product {
                            opacity: 0; // Fade out
                            visibility: hidden; // Make it invisible after opacity transition
                            transition: opacity 1s ease, visibility 0.5s 0s; // Delay visibility change until after opacity transition
                        }
                    }
                }
                .Gallery{
                    // &:hover {
                    //    .subMenu_gallery {
                    //         display: flex;
                    //    }
                    // }
                    &:hover {
                        .subMenu_gallery {
                            opacity: 1; // Fully visible
                            visibility: visible; // Make it visible
                            transition: opacity 1s ease, visibility 0.5s; // Remove the delay for visibility
                        }
                    }
                
                    &:not(:hover) {
                        .subMenu_gallery {
                            opacity: 0; // Fade out
                            visibility: hidden; // Make it invisible after opacity transition
                            transition: opacity 1s ease, visibility 0.5s 0s; // Delay visibility change until after opacity transition
                        }
                    }
                }
                .selectedmenuItem {
                    color: $color-gold; /* Color for selected item, e.g., a shade of blue */
                }
            }
            .right {
                display: flex;
                flex-direction: row; //row|row-reverse|column|column-reverse
                justify-content: flex-end; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                align-items: center; //stretch|center|flex-start|flex-end
                flex-wrap: nowrap; //|wrap|wrap-reverse
                margin: 0 2rem 0 0;
            }
    
        }
        .backShadow {
            display: none;
            opacity: 0;
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 2; /* Ensure .backShadow is behind .subMenu */
            background-color: rgba(255, 255, 255, 0);
            backdrop-filter: blur(0.2px);
            animation: fade-in-out 0.5s ease;
        }
        
        .appear {
            opacity: 1;
            display: block;
        }
        
      
        .rightControl {
            position: fixed;
            right: 1rem;
            bottom: 1rem;
            z-index: 1000; // rightControl
        }
    
        .disappear {
            opacity: 0;
            display: none;
        }
        .sideBar {
            display: none;
        }
    }
}