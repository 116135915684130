// @import './../../../assets/styles/font.css';
@import './../../../assets/styles/variables.module';
@import './../../../assets/styles/palette.module';

.Footer {
    background: url(../../../assets/images/background/dark_green.jpg) repeat top center;
    color: white;
    // position: absolute;
    // bottom: 0;
    width: 100%;
    padding: 2rem 0;
    margin: 0 auto 0 auto;
    .container {
        display: flex;
        flex-direction: column; //row|row-reverse|column|column-reverse
        justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
        align-items: center; //stretch|center|flex-start|flex-end
        flex-wrap: nowrap; //|wrap|wrap-reverse
        margin: 1rem 0 0 0;
        .left {
            padding: 0rem 0rem;
            width: 100%;
            .title {
                text-align: center;
                font-size: $font-size-heading;
                font-weight: bold;
                padding: 0 0 0 0;
                margin: 0rem 0 0 0;
            }
            .row {
                display: flex;
                flex-direction: row; //row|row-reverse|column|column-reverse
                justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                align-items: center; //stretch|center|flex-start|flex-end
                flex-wrap: nowrap; //|wrap|wrap-reverse
                padding: 0 0.5rem;
                margin: 1rem 0;
                width: 100%;
                .key {
                    font-weight: bold;
                    text-align: left;
                    width: 10rem;
                }
                .value {
                    width: 10rem;
                    text-align: left;
                }
                a {
                    width: 10rem;
                    display: block;
                    color: white;
                    
                }
            }
        }
        .right {
            position: relative;
            background-size: cover; 
            padding: 0 0.5rem;
            .sealWrapper {
                img {
                    width: 30rem;
                    opacity: 0.1;
                    position: absolute;
                    right: -20rem;
                    bottom: 0;
                }
            }
            .connect {
                display: flex;
                flex-direction: column; //row|row-reverse|column|column-reverse
                justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                align-items: center; //stretch|center|flex-start|flex-end
                flex-wrap: nowrap; //|wrap|wrap-reverse
            }
            .item {
                display: flex;
                flex-direction: row; //row|row-reverse|column|column-reverse
                justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                align-items: center; //stretch|center|flex-start|flex-end
                flex-wrap: nowrap; //|wrap|wrap-reverse
                margin: 1rem 0;
            }
        }
    }
}
// khi width screen trên 1300px thì áp dụng css này


@media (min-width: 400px){
    .Footer {
        background: url(../../../assets/images/background/dark_green.jpg) repeat top center;
        color: white;
        // position: absolute;
        // bottom: 0;
        width: 100%;
        padding: 2rem 0;
        margin: 0 auto;
        .container {
            display: flex;
            flex-direction: column; //row|row-reverse|column|column-reverse
            justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
            align-items: center; //stretch|center|flex-start|flex-end
            flex-wrap: nowrap; //|wrap|wrap-reverse
            width: 100%;
            margin: 1rem auto 0 auto;
            padding: 0 0.5rem;
            .left {
                padding: 0rem 0rem;
                width: 100%;
                .title {
                    text-align: center;
                    font-size: $font-size-heading;
                    font-weight: bold;
                    padding: 0 0 0 0;
                    margin: 0rem 0 0 0;
                }
                .row {
                    display: flex;
                    flex-direction: row; //row|row-reverse|column|column-reverse
                    justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                    align-items: center; //stretch|center|flex-start|flex-end
                    flex-wrap: nowrap; //|wrap|wrap-reverse
                    padding: 0 0.5rem;
                    margin: 1rem 0;
                    width: 100%;
                    .key {
                        font-weight: bold;
                        text-align: left;
                        width: 10rem;
                    }
                    .value {
                        width: 10rem;
                        text-align: left;
                    }
                    a {
                        width: 10rem;
                        display: block;
                        color: white;
                        
                    }
                }
            }
            .right {
                position: relative;
                background-size: cover; 
                padding: 0 0.5rem;
                .sealWrapper {
                    img {
                        width: 30rem;
                        opacity: 0.1;
                        position: absolute;
                        right: -20rem;
                        bottom: 0;
                    }
                }
                .connect {
                    display: flex;
                    flex-direction: column; //row|row-reverse|column|column-reverse
                    justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                    align-items: center; //stretch|center|flex-start|flex-end
                    flex-wrap: nowrap; //|wrap|wrap-reverse
                }
                .item {
                    display: flex;
                    flex-direction: row; //row|row-reverse|column|column-reverse
                    justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                    align-items: center; //stretch|center|flex-start|flex-end
                    flex-wrap: nowrap; //|wrap|wrap-reverse
                    margin: 1rem 0;
                }
            }
        }
    }
}
@media (min-width: 700px){
    .Footer {
        display: block;
        background: url(../../../assets/images/background/dark_green.jpg) repeat top center;
        color: white;
        // position: absolute;
        // bottom: 0;
        width: 100%;
        padding: 2rem 0;
        margin: 0 auto;
        .container {
            display: flex;
            flex-direction: column; //row|row-reverse|column|column-reverse
            justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
            align-items: center; //stretch|center|flex-start|flex-end
            flex-wrap: nowrap; //|wrap|wrap-reverse
            width: 100%;
            margin: 1rem auto 0 auto;
            padding: 0 0.5rem;
            .left {
                padding: 0rem 0rem;
                width: 100%;
                .title {
                    text-align: center;
                    font-size: $font-size-heading;
                    font-weight: bold;
                    padding: 0 0 0 0;
                    margin: 0rem 0 0 0;
                }
                .row {
                    display: flex;
                    flex-direction: row; //row|row-reverse|column|column-reverse
                    justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                    align-items: center; //stretch|center|flex-start|flex-end
                    flex-wrap: nowrap; //|wrap|wrap-reverse
                    padding: 0 10rem;
                    margin: 1rem 0;
                    width: 100%;
                    .key {
                        font-weight: bold;
                        text-align: left;
                        width: 10rem;
                    }
                    .value {
                        width: 10rem;
                        text-align: left;
                    }
                    a {
                        width: 10rem;
                        display: block;
                        color: white;
                        
                    }
                }
            }
            .right {
                position: relative;
                background-size: cover; 
                padding: 0 0.5rem;
                .sealWrapper {
                    img {
                        width: 30rem;
                        opacity: 0.1;
                        position: absolute;
                        right: -20rem;
                        bottom: 0;
                    }
                }
                .connect {
                    display: flex;
                    flex-direction: column; //row|row-reverse|column|column-reverse
                    justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                    align-items: center; //stretch|center|flex-start|flex-end
                    flex-wrap: nowrap; //|wrap|wrap-reverse
                }
                .item {
                    display: flex;
                    flex-direction: row; //row|row-reverse|column|column-reverse
                    justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                    align-items: center; //stretch|center|flex-start|flex-end
                    flex-wrap: nowrap; //|wrap|wrap-reverse
                    margin: 1rem 0;
                }
            }
        }
    }
}
@media (min-width: 1300px) {
    .Footer {
        background: url(../../../assets/images/background/dark_green.jpg) repeat top center;
        color: white;
        width: 100%;
        padding: 1rem 0;
        margin: 0 0 0 0;
        .container {
            // border: 1px solid blue;
            padding: 0 0 0 0;
            margin: 0 0 0 0;
            display: grid;
            grid-template-columns: 50% 50%;
            justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
            align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
            align-items: center;//top, center, end
            align-self: center;
            text-align: center;
            .left {
                padding: 1rem 10rem;
                .title {
                    text-align: center;
                    font-size: $font-size-heading;
                    font-weight: bold;
                    padding: 0 0 0 0;
                    margin: 0rem 0 0 0;
                }
                .row {
                    display: flex;
                    flex-direction: row; //row|row-reverse|column|column-reverse
                    justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                    align-items: center; //stretch|center|flex-start|flex-end
                    flex-wrap: nowrap; //|wrap|wrap-reverse
                    padding: 0 0;
                    margin: 1rem 0;
                    .key {
                        font-weight: bold;
                        text-align: left;
                        width: 10rem;
                    }
                    .value {
                        width: 10rem;
                        text-align: left;
                    }
                    a {
                        width: 10rem;
                        display: block;
                        color: white;
                        
                    }
                }
            }
            .right {
                .sealWrapper {
                    img {
                        width: 30rem;
                        opacity: 0.1;
                        position: absolute;
                        right: -20rem;
                        bottom: 0;
                    }
                }
                .item {
                    display: flex;
                    flex-direction: row; //row|row-reverse|column|column-reverse
                    justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                    align-items: center; //stretch|center|flex-start|flex-end
                    flex-wrap: nowrap; //|wrap|wrap-reverse
                }
            }
        }
    }
}