@import './../../assets/styles/variables.module';
@import './../../assets/styles/palette.module';

.Contact {
    width: 100%;
    margin: 0rem auto 0 auto;
    box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
    box-sizing: border-box;
    padding: 2rem 1rem 20rem 1rem;
    background: url(../../assets/images/background/dark_green.jpg) repeat top center;
    color: white;
    
    .left {
        .title {
            text-align: center;
            font-size: $font-size-heading;
            font-weight: bold;
            padding: 0 0 0 0;
            margin: 0rem 0 0 0;
        }
        .row {
            display: flex;
            flex-direction: row; //row|row-reverse|column|column-reverse
            justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|
            align-items: flex-start; //stretch|center|flex-start|flex-end
            flex-wrap: nowrap; //|wrap|wrap-reverse
            padding: 0 0;
            margin: 1rem 0;
            .key {
                font-weight: bold;
                text-align: left;
                width: 40%;
            }
            .value {
                width: 60%;
            }
            a {
                width: 60%;
                display: block;
                color: white;
                
            }
        }
    }
    .right {
        .map {
            .row {
                display: flex;
                flex-direction: row; //row|row-reverse|column|column-reverse
                justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                align-items: flex-start; //stretch|center|flex-start|flex-end
                flex-wrap: nowrap; //|wrap|wrap-reverse
                padding: 0 0;
                margin: 1rem 0;
                border-top: 1px solid rgba(184, 184, 184, 0.893);
                padding: 1rem 0 0 0;
                .key {
                    font-weight: bold;
                    text-align: left;
                    width: 40%;
                }
                .value {
                    width: 60%;
                }
            }
            .mapContainer {
                position: relative;
                width: 100%;
                padding-top: 50%; /* 2:1 Aspect Ratio */
                overflow: hidden;
                background: #eee; /* Optional: background color in case the iframe doesn't load */
                margin-top: 20px;
                text-align: center;
                
                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: 4px solid white;
                    box-sizing: border-box;
                }
            }
        }
    }
}

// khi width screen trên 600px thì áp dụng css này
@media (min-width: 600px) {
    .Contact {
        padding: 2rem 2rem 0 2rem;
    }
}
@media (min-width: 900px) {
    .Contact {
        padding: 2rem 8rem 0 8rem;
    }
}
@media (min-width: 1000px) {
    .Contact {
        width: 100%;
        height: 100vh;
        margin: 0rem auto 0 auto;
        box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
        box-sizing: border-box;
        padding: 2rem 1rem 0 1rem;
        background: url(../../assets/images/background/dark_green.jpg) repeat top center;
        color: white;
        min-height: 95vh;
        .left {
            padding: 0 20rem;
            .title {
                text-align: center;
                font-size: $font-size-heading;
                font-weight: bold;
                padding: 0 0 0 0;
                margin: 0rem 0 0 0;
            }
            .row {
                display: flex;
                flex-direction: row; //row|row-reverse|column|column-reverse
                justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                align-items: center; //stretch|center|flex-start|flex-end
                flex-wrap: nowrap; //|wrap|wrap-reverse
                padding: 0 0;
                margin: 1rem 0;
                .key {
                    font-weight: bold;
                    text-align: left;
                    width: 10rem;
                }
                .value {
                    width: 10rem;
                }
                a {
                    width: 10rem;
                    display: block;
                    color: white;
                    
                }
            }
        }
        .right {
            display: grid;
            grid-template-columns: 45% 45%;
            justify-content: space-evenly; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
            align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
            align-items: center;//top, center, end
            align-self: center;
            text-align: center;
            .map {
                .row {
                    border-top: none;
                    display: flex;
                    flex-direction: row; //row|row-reverse|column|column-reverse
                    justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                    align-items: flex-start; //stretch|center|flex-start|flex-end
                    flex-wrap: nowrap; //|wrap|wrap-reverse
                    padding: 0 0;
                    margin: 1rem 0;
                    .key {
                        font-weight: bold;
                        text-align: left;
                        width: 10rem;
                    }
                    .value {
                        width: 30rem;
                        text-align: left;

                    }
                }
                .mapContainer {
                    position: relative;
                    width: 100%;
                    padding-top: 50%; /* 2:1 Aspect Ratio */
                    overflow: hidden;
                    background: #eee; /* Optional: background color in case the iframe doesn't load */
                    margin-top: 20px;
                    text-align: center;
                    
                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border: 10px solid white;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
}