@import './assets/styles/font.css';
@import './assets/styles/palette.module';

html, body {
  margin: 0;
  font-family: "Roboto-Regular", sans-serif; /* Ensure fallback font is specified */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  height: 100%;
}
/* Style for the scrollbar container */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

/* Style for the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5); /* Color of the scrollbar thumb */
  border-radius: 10px; /* Round the corners of the scrollbar thumb */
}

/* Style for the scrollbar track (the area the thumb slides in) */
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1); /* Color of the scrollbar track */
  border-radius: 10px; /* Round the corners of the scrollbar track */
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
