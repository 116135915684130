@import './../../../assets/styles/palette.module';
#root {
    box-sizing: border-box;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
}
.component_Sidebar {
    // width: 15vw;
    width: 200px;
    height: 100vh;
    padding: 0.5rem 0.5rem 0 0.5rem;
    // box-shadow: 10px 0 10px -5px rgba(192, 190, 190, 0.893); /* Adjust the values as needed */
    border-right: 1px solid rgba(184, 184, 184, 0.893);
    position: fixed;
    left: 0;
    top: 0;
    backdrop-filter: blur(20px);
    z-index: 4; // component_Sidebar
    background-color: white;
    box-shadow: 0 0 20px rgba(192, 190, 190, 0.893);
    border: none;
    display: flex;
    flex-direction: column; //row|row-reverse|column|column-reverse
    justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|
    align-items: center; //stretch|center|flex-start|flex-end
    flex-wrap: nowrap; //|wrap|wrap-reverse
    .top {
        width: 100%;
        .rowTop { 
            display: grid;
            grid-template-columns: 50% 50%;
            justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
            align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
            align-items: center; //top, center, end
            align-self: center;
            text-align: center;
            padding: 0 0 0.5rem 0;
            .boxLogo {
                display: flex;
                flex-direction: row; //row|row-reverse|column|column-reverse
                justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                align-items: center; //stretch|center|flex-start|flex-end
                flex-wrap: nowrap; //|wrap|wrap-reverse
    
                img {
                    width: 3rem;
                }
            }
    
            .boxIconMenu {
                display: flex;
                flex-direction: row; //row|row-reverse|column|column-reverse
                justify-content: flex-end; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                align-items: center; //stretch|center|flex-start|flex-end
                flex-wrap: nowrap; //|wrap|wrap-reverse
    
                .iconMenu {
                    text-align: right;
                    cursor: pointer;
                    border-radius: 1rem;
    
                    &:hover {
                        box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
                    }
    
                    &:active {
                        box-shadow: none;
                    }
                }
            }
        }
    
        .rowName {
            display: flex;
            flex-direction: row; //row|row-reverse|column|column-reverse
            justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|
            align-items: center; //stretch|center|flex-start|flex-end
            flex-wrap: nowrap; //|wrap|wrap-reverse
            margin: 0.5rem 0;
            p {
                font-weight: bold;
                padding: 0 0 0 0;
                margin: 0 0 0 0;
            }
    
            .name {
                text-align: left;
            }
        }
        .listItemSideBar {
            margin: 2rem 0 0 0;
            .itemSideBar {
                text-align: left;
                padding: 1rem 0 1rem 0.5rem;
                cursor: pointer;
                &:hover {
                    color: #0d6efd;
                    transition: 0.5s;
                    font-weight: bold;
                }
            }
        }
    }
    .bottom {
        // justify-content: flex-end; //flex-start|flex-end|center|space-between|space-around|space-evenly|
        width: 100%;
        margin: 0 0 4rem 0;
        padding: 0 0 7rem 0;
        .itemSideBar {
            text-align: left;
            padding: 0.5rem 0 0.5rem 0.5rem;
            cursor: pointer;
        }
        .language {
            display: flex;
            flex-direction: row; //row|row-reverse|column|column-reverse
            justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|
            align-items: center; //stretch|center|flex-start|flex-end
            flex-wrap: nowrap; //|wrap|wrap-reverse
            // border: 1px solid blue;
            width: 70%;
            margin: 0 auto;
        }
    }
  
}
.backShadow {
    display: none;
    opacity: 0;
    position: fixed;
    z-index: 2; // backShadow
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0);
    backdrop-filter: blur(0.2px);
    // cho nổi lên, bao trùm màn hình, làm mờ end
    animation: fade-in-out 0.5sn ease;
}
.appear {
    opacity: 1;
    display: block;
}
.disappear {
    opacity: 0;
    display: none;
}
.moveLeftSidebar {
    transform: translateX(-17vw);
    opacity: 0; /* Add opacity change */
    transition: transform 0.5s ease, opacity 0.5s ease; /* Combine transitions */
    display: none;
}
.moveRightSidebar {
    transform: translateX(0vw);
    opacity: 1; /* Ensure opacity is 1 when moving right */
    transition: transform 0.5s ease, opacity 0.5s ease; /* Combine transitions */
}
.moveRightBoxMenubar {
    transform: translateX(2vw);
    transition: transform 0.5s ease; 
}
.moveLeftBoxMenubar {
    transform: translateX(-50px);
    transition: transform 0.5s ease; 
}
.BoxMenubar { 
    position: absolute;
    border-radius: 1rem;
    left: -1vw;
    top: 0.5rem;
    cursor: pointer;
    z-index: 1000; // áp dụng cho burger menu hien ra khi sidebar an di
}