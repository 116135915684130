// @import './../../../assets/styles/font.css';
@import './../../../assets/styles/variables.module';
@import './../../../assets/styles/palette.module';

// khi width screen trên 300px thì áp dụng css này
@media (min-width: 300px) {
    .BottomMenuBar {
        display: grid;
        grid-template-columns: repeat(4, 1fr); // cleaner syntax for 4 equal columns
        justify-content: space-between;
        align-content: center;
        align-items: center;
        text-align: center;
        
        position: fixed;
        bottom: 0;
        width: 100%;         // Make the menu span the entire width of the viewport
        z-index: 100;        // Ensure the menu stays on top of other elements
        background-color: #fff; // Add a background color to ensure it is visible
        padding: 0 0;   // Adjust padding to your needs
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); // Optional: Adds a shadow for a floating effect
        .box {
            // border: 1px solid red;
            padding: 0.2rem 0;
            cursor: pointer;
            transition: all 0.3s; // Optional: Add a smooth transition effect
            &:hover {
               color: #0d6efd;
            }
            &:active {
                transform: scale(0.95); // Slightly scale down the box when clicked
                background-color: rgba(13, 110, 253, 0.1); // Optional: Add background change on click
            }
            .name {
                font-size: $font-size-small;
            }

        }
        .selected {
            color: #0d6efd; // Blue color for the selected box
        }
    }
}
@media (min-width: 1100px) {
    .BottomMenuBar {
        display: none;
    }
}