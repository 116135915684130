@import './../../assets/styles/variables.module';
@import './../../assets/styles/palette.module';

@media (min-width: 300px){
    .ProductGroup{
        .rowIcon {
            display: none;
            flex-direction: row; //row|row-reverse|column|column-reverse
            justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
            align-items: flex-end; //stretch|center|flex-start|flex-end
            flex-wrap: nowrap; //|wrap|wrap-reverse
            // background-color: $color-dodger-blue;
            padding: 0 0 0.5rem 0;
            height: 5rem;
            background-color: rgb(241, 241, 241);
            // border: 1px solid red;
            .box {
                width: 10rem;
                cursor: pointer;
                transition: transform 0.5s ease-in-out; /* Add this line */
                .icon {
                    img {
                        display: block;
                        margin: 0 auto;
                        width: 30%;
                    }
                    .door {
                        width: 10%;
                    }
                }
                .name {
                    text-align: center;
                    margin: 0.5rem 0 0 0;
                }
                &:hover {
                    transform: translateZ(-10px) scale(1.1);// phóng to ra
                }
            }
        }
        .structure {
            height: 30rem;
            width: 100%;
            margin: 0 0 1rem 0;
            display: flex;
            justify-content: center;
            align-items: flex-end; /* Align content to the bottom */
            .bgColor {
                width: 100%;
                .sample {
                    height: 30rem;
                    width: 100%;
                    // border: 1px solid red;
                    color: white;
                    padding: 3rem 1rem 0 1rem;
                    text-shadow: -1px -1px 0 #000,  1px -1px 0 #000,-1px  1px 0 #000,1px  1px 0 #000;
                    .title {
                        text-align: center;
                        font-size: $font-size-title;
                        font-weight: bold;
                        margin: 0 0 3rem 0;
        
                    }
                    .intro {
                        // text-align: center;
                        // width: 50%;
                        // margin: 0 auto;
                        // letter-spacing: 0.2rem;
                        // font-size: $font-size-subheading;

                        width: 90%;
                        margin: 0 auto;
                        word-break: break-word; /* Allows long words to be broken and wrapped */
                        overflow-wrap: break-word; /* Ensures text breaks to prevent overflow */
                        line-height: 1.5; /* Optional: Adjust line height for better readability */
                        font-size: $font-size-small;
                    }
        
                    .view {
                        text-align: center;
                        width: fit-content;
                        margin: 2rem auto;
                        padding: 0.5rem 1rem;
                        letter-spacing: 0.2rem;
                        border: 1px solid rgb(255, 255, 255);
                        color: white;
                        background-color: transparent;
                        transition: all 0.2s ease; /* Smooth transition for all properties */
                        cursor: pointer; /* Pointer cursor to indicate interactivity */
                        &:hover {
                        box-shadow: 0 0 10px rgba(255, 255, 255, 0.893);
                        }
                        &:active{
                            box-shadow: none;
                        }
                    }
                }
            }
        }
        .foamSheet {
            background: url(./../../assets/images/ProductSample/1.jpg) no-repeat center center;
            background-size: cover;
            .bgColor {
                background-color: rgba(217, 255, 0, 0.212);
                .sample {
                    background: url(./../../assets/images/ProductSample/foamsheet.png) no-repeat center center;
                    background-size: cover;
                }
            }
        }
        .wallPanel {
            background: url(./../../assets/images/ProductSample/2.jpg) no-repeat center center;
            background-size: cover;
            .bgColor {
                background-color: rgba(0, 140, 255, 0.212);
            }
        }
        .doorWall {
            background: url(./../../assets/images/ProductSample/3.jpg) no-repeat center center;
            background-size: cover;
           
            .bgColor {
                background-color: rgba(255, 0, 242, 0.212);
            }
        }
        .wallPatition {
            background: url(./../../assets/images/ProductSample/4.jpg) no-repeat center center;
            background-size: cover;
            .bgColor {
                background-color: rgba(0, 255, 0, 0.212);
            }
        }
        .moulding {
            background: url(./../../assets/images/ProductSample/5.jpg) no-repeat center center;
            background-size: cover;
            .bgColor {
                background-color: rgba(255, 0, 0, 0.212);
            }
        }
        .trimSheet {
            background: url(./../../assets/images/ProductSample/6.jpg) no-repeat center center;
            background-size: cover;
            .bgColor {
                background-color: rgba(251, 255, 0, 0.212);
                .sample {
                    background: url(./../../assets/images/ProductSample/trimsheet.png) no-repeat center center;
                    background-size: cover;
                }
            }
        }
    }
}
@media (min-width: 500px){
    .ProductGroup{
        .structure {
            .bgColor {
                .sample {
                    width: 100%;
                    p:nth-child(2) {
                        display:block;
                        width: 80%;
                        margin: 0 auto;
                        word-break: break-word; /* Allows long words to be broken and wrapped */
                        overflow-wrap: break-word; /* Ensures text breaks to prevent overflow */
                        line-height: 1.5; /* Optional: Adjust line height for better readability */
                        font-size: $font-size-paragraph;
                    }
                }
            }
        }
    }
}
@media (min-width: 700px) {
    .ProductGroup{
        .rowIcon {
            display: flex;
        }
        .structure {
            .bgColor {
                .sample {
                    padding: 5rem 0 0 0;
                    width: 100%;
                    p:nth-child(2) {
                        display:block;
                        width: 70%;
                        margin: 0 auto;
                        word-break: break-word; /* Allows long words to be broken and wrapped */
                        overflow-wrap: break-word; /* Ensures text breaks to prevent overflow */
                        line-height: 1.5; /* Optional: Adjust line height for better readability */
                        font-size: $font-size-paragraph;

                    }
                }
            }
        }
    }
}
@media (min-width: 1000px) {
    .ProductGroup{
        .structure {
            .bgColor {
                .sample {
                    // width: 100%;
                    p:nth-child(2) {
                        display: block;
                        width: 50%;
                        margin: 0 auto;
                        word-break: break-word; /* Allows long words to be broken and wrapped */
                        overflow-wrap: break-word; /* Ensures text breaks to prevent overflow */
                        line-height: 1.5; /* Optional: Adjust line height for better readability */
                        font-size: $font-size-subheading;
                    }
                }
            }
        }
    }
}