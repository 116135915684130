

.CatalogBook {
    background: url('./../../assets/images/background/wood2.jpg') repeat top center;
    min-height: 90vh;
    position: relative;
    // .mobileViewWrapper {
    //     button {
    //         margin: 10rem 0 0 40%;
    //         padding: 0.5rem 1rem;
    //         background: #0d6efd;
    //         border-radius: 0.5rem;
    //         outline: none;
    //         border: none;
    //         box-shadow: 0 0 10px rgba(149, 149, 149, 0.893);
    //         color: white;
    //         cursor: pointer;
    //         &:active{
    //            box-shadow: none;
    //         }
    //     }
    // }
    .content {
        .navBar {
            display: flex;
            flex-direction: column; //row|row-reverse|column|column-reverse
            justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
            align-items: center; //stretch|center|flex-start|flex-end
            flex-wrap: nowrap; //|wrap|wrap-reverse
            position: absolute;
            left: 2rem;
            top: 5rem;
            .btnWrapper {
                padding: 0.5rem 1rem;
                // background: #0d6efd;
                border-radius: 0.5rem;
                outline: none;
                border: none;
                box-shadow: 0 0 10px rgba(149, 149, 149, 0.893);
                color: black;
                cursor: pointer;
                margin: 0 0 1rem 0;
                background: rgba(255, 255, 255, 0.692);
                display: flex;
                flex-direction: row; //row|row-reverse|column|column-reverse
                justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                align-items: center; //stretch|center|flex-start|flex-end
                flex-wrap: nowrap; //|wrap|wrap-reverse
                &:hover{
                    box-shadow: 0 0 10px rgba(238, 238, 238, 0.893);
                }
                &:active{
                    box-shadow: 0 0 10px rgba(214, 214, 214, 0.893);
                }
          
            }
        }
        
        .left {
            position: absolute;
            top: 50%;
            left: 2rem;
            .btnWrapper {
                padding: 0.5rem 1rem;
                // background: #0d6efd;
                border-radius: 0.5rem;
                outline: none;
                border: none;
                box-shadow: 0 0 10px rgba(149, 149, 149, 0.893);
                color: black;
                cursor: pointer;
                background: rgba(255, 255, 255, 0.692);
                display: flex;
                flex-direction: row; //row|row-reverse|column|column-reverse
                justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                align-items: center; //stretch|center|flex-start|flex-end
                flex-wrap: nowrap; //|wrap|wrap-reverse
                &:hover{
                    box-shadow: 0 0 10px rgba(238, 238, 238, 0.893);
                }
                &:active{
                    box-shadow: 0 0 10px rgba(214, 214, 214, 0.893);
                }

            }
        }
        .right {
            position: absolute;
            top: 50%;
            right: 2rem;
            .btnWrapper {
                padding: 0.5rem 1rem;
                // background: #0d6efd;
                border-radius: 0.5rem;
                outline: none;
                border: none;
                box-shadow: 0 0 10px rgba(149, 149, 149, 0.893);
                color: black;
                cursor: pointer;
                background: rgba(255, 255, 255, 0.692);
                display: flex;
                flex-direction: row; //row|row-reverse|column|column-reverse
                justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                align-items: center; //stretch|center|flex-start|flex-end
                flex-wrap: nowrap; //|wrap|wrap-reverse
                &:hover{
                    box-shadow: 0 0 10px rgba(238, 238, 238, 0.893);
                }
                &:active{
                    box-shadow: 0 0 10px rgba(214, 214, 214, 0.893);
                }
           
            }
        }
        .demoPage {
            box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
            .stf__parent {
                margin: auto !important;
            }
        }
        .animationWrapper {
            opacity: 1;
            display: block;
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 150; /* Ensure .backShadow is behind .subMenu */
            background-color: rgba(255, 255, 255, 0.705);
            backdrop-filter: blur(0.5px);
            .animation {
                width: 70%;
                margin: 20rem auto;
                width: 200px;
                height: 200px;
            }
        }
    }
}