@import './../../assets/styles/variables.module';



.component_SignIn {
   width: 100%;
   margin: 0 auto;
   height: 100vh;
   border: 1px solid white;

   // border: 1px solid red;
   .container {
      width: 85%;
      margin: 20% auto 0 auto;
      box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
      border-radius: 1rem;
      padding: 1rem 0 0 0;
      .title {
         font-size:  $font-size-heading;
         text-align: center;
         font-weight: bold;
         margin: 1rem 0;
      }

      .formLogin {
         padding: 0 0rem 2rem 0rem;
         width: 100%;
         .boxInput {
            padding: 0 2rem;
            .row {
               display: flex;
               flex-direction: column; //row|row-reverse|column|column-reverse
               justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
               align-items: flex-start; //stretch|center|flex-start|flex-end
               flex-wrap: nowrap; //|wrap|wrap-reverse
               .key {
                  font-size: 17px;
                  font-weight: bold;
                  text-align: left;
                  padding: 0 0 0 0;
                  margin: 1rem 0 0 0;
               }

               .boxValue {
                  display: grid;
                  grid-template-columns: 10% 80% 10%;
                  justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
                  align-content: flex-start; // start || center || end || stretch || space-around || space-between || space-evenly
                  align-items: center; //top, center, end
                  align-self: center;
                  text-align: center;
                  border-bottom: 1px solid rgba(184, 184, 184, 0.893);
                  padding: 0 0 0 0;
                  margin: 0 0 0 0;
                  width: 100%;
                  
                  input {
                     padding: 1rem 1rem;
                     border-radius: 5px;
                     outline: none;
                     border: none;
                     width: 100%;
                     // box-sizing: border-box;
                     font-size: 17px;
                     background-color: white;

                     &:active {
                        background-color: white;
                     }

                     &:hover {
                        background-color: white;
                     }
                  }
               }
            }

            .rowError {
               display: flex;
               flex-direction: column; //row|row-reverse|column|column-reverse
               justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
               align-items: flex-start; //stretch|center|flex-start|flex-end
               flex-wrap: nowrap; //|wrap|wrap-reverse
               // border: 1px solid blue;
               .key {
                  display: none;
               }
               .boxValue {
                  display: grid;
                  grid-template-columns: 10% 80% 10%;
                  justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
                  align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
                  align-items: center; //top, center, end
                  align-self: center;
                  text-align: center;
                  width: 100%;
                  .icon {
                     color: rgb(246, 28, 77);
                  }

                  p {
                     padding: 0 0 0 0;
                     margin: 0 0 0 0;
                     text-align: left;
                     color: rgb(246, 28, 77);
                  }
               }
            }
         }
         .btnWrapper {
            width: 70%;
            margin: 2rem auto 0 auto;
            display: flex;
            flex-direction: row; //row|row-reverse|column|column-reverse
            justify-content: space-evenly; //flex-start|flex-end|center|space-between|space-around|space-evenly|
            align-items: center; //stretch|center|flex-start|flex-end
            flex-wrap: nowrap; //|wrap|wrap-reverse
         }
      }
   }
}
// khi width screen trên 550px thì áp dụng css này
@media (min-width: 550px) {
   .component_SignIn {
      width: 100%;
      margin: 0 auto;
      border: 1px solid white;
   
      .container {
         width: 80%;
         margin: 10rem auto 0 auto;
         box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
         border-radius: 1rem;
         padding: 0 3rem;
         box-sizing: border-box;
         .formLogin {
            padding: 2rem 0rem 2rem 0rem;
            width: 100%;
            .title {
               font-size:  $font-size-heading;
               text-align: center;
               font-weight: bold;
               margin: 1rem 0;
            }
        
            .boxInput {
               padding: 0 0rem;
               margin: 0 0 2rem 0;
               .row {
                  display: grid;
                  grid-template-columns: 30% 70%;
                  justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
                  align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
                  align-items: center; //top, center, end
                  align-self: center;
                  text-align: center;
                  padding: 0 0 0 0;
                  margin: 0 0 0 0;
                  .key {
                     font-size: 17px;
                     font-weight: bold;
                     text-align: left;
                     padding: 0 0 0 0;
                     margin: 0 0 0 0;
                  }
   
                  .boxValue {
                     display: grid;
                     grid-template-columns: 10% 80% 10%;
                     justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
                     align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
                     align-items: center; //top, center, end
                     align-self: center;
                     text-align: center;
                     border-bottom: 1px solid rgba(184, 184, 184, 0.893);
                     .eye {
                        cursor: pointer;
   
                        &:active {
                           color: gray;
                        }
                     }
   
                     input {
                        padding: 1rem 1rem;
                        border-radius: 5px;
                        outline: none;
                        border: none;
                        width: 100%;
                        // box-sizing: border-box;
                        font-size: 17px;
                        background-color: white;
   
                        &:active {
                           background-color: white;
                        }
   
                        &:hover {
                           background-color: white;
                        }
                     }
                  }
               }
   
               .rowError {
                  display: flex;
                  flex-direction: row; //row|row-reverse|column|column-reverse
                  justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                  align-items: center; //stretch|center|flex-start|flex-end
                  flex-wrap: nowrap; //|wrap|wrap-reverse
                  .space {
                     padding: 0 0 0 0;
                     margin: 0 0 0 0;
                     width: 30%;
                  }
                  .boxValue {
                     width: 70%;
                     display: grid;
                     grid-template-columns: 10% 80% 10%;
                     justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
                     align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
                     align-items: center; //top, center, end
                     align-self: center;
                     text-align: center;
                     .icon {
                        color: rgb(246, 28, 77);
                     }
   
                     p {
                        padding: 0 0 0 0;
                        margin: 0 0 0 0;
                        text-align: left;
                        color: rgb(246, 28, 77);
                     }
                  }
               }
            }
   
            //end row
            .btnWrapper {
               width: 70%;
               margin: 2rem auto;
               display: flex;
               flex-direction: row; //row|row-reverse|column|column-reverse
               justify-content: space-evenly; //flex-start|flex-end|center|space-between|space-around|space-evenly|
               align-items: center; //stretch|center|flex-start|flex-end
               flex-wrap: nowrap; //|wrap|wrap-reverse
            }
         }
      }
   }
}
@media (min-width: 900px) {
   .component_SignIn {
      width: 100%;
      margin: 0 auto;
      // height: 100vh;
      border: 1px solid white;
   
      .container {
         width: 40%;
         margin: 10rem auto 0 auto;
         box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
         border-radius: 1rem;
         padding: 0 3rem;
         box-sizing: border-box;
         .formLogin {
            padding: 2rem 0rem 2rem 0rem;
            width: 100%;
            .title {
               font-size:  $font-size-heading;
               text-align: center;
               font-weight: bold;
               margin: 1rem 0;
            }
        
            .boxInput {
               padding: 0 0rem;
               margin: 0 0 2rem 0;
               .row {
                  display: grid;
                  grid-template-columns: 30% 70%;
                  justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
                  align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
                  align-items: center; //top, center, end
                  align-self: center;
                  text-align: center;
                  padding: 0 0 0 0;
                  margin: 0 0 0 0;
                  .key {
                     font-size: 17px;
                     font-weight: bold;
                     text-align: left;
                     padding: 0 0 0 0;
                     margin: 0 0 0 0;
                  }
   
                  .boxValue {
                     display: grid;
                     grid-template-columns: 10% 80% 10%;
                     justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
                     align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
                     align-items: center; //top, center, end
                     align-self: center;
                     text-align: center;
                     border-bottom: 1px solid rgba(184, 184, 184, 0.893);
                     .eye {
                        cursor: pointer;
   
                        &:active {
                           color: gray;
                        }
                     }
   
                     input {
                        padding: 1rem 1rem;
                        border-radius: 5px;
                        outline: none;
                        border: none;
                        width: 100%;
                        // box-sizing: border-box;
                        font-size: 17px;
                        background-color: white;
   
                        &:active {
                           background-color: white;
                        }
   
                        &:hover {
                           background-color: white;
                        }
                     }
                  }
               }
   
               .rowError {
                  display: flex;
                  flex-direction: row; //row|row-reverse|column|column-reverse
                  justify-content: center; //flex-start|flex-end|center|space-between|space-around|space-evenly|
                  align-items: center; //stretch|center|flex-start|flex-end
                  flex-wrap: nowrap; //|wrap|wrap-reverse
                  .space {
                     padding: 0 0 0 0;
                     margin: 0 0 0 0;
                     width: 30%;
                  }
                  .boxValue {
                     width: 70%;
                     display: grid;
                     grid-template-columns: 10% 80% 10%;
                     justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
                     align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
                     align-items: center; //top, center, end
                     align-self: center;
                     text-align: center;
                     .icon {
                        color: rgb(246, 28, 77);
                     }
   
                     p {
                        padding: 0 0 0 0;
                        margin: 0 0 0 0;
                        text-align: left;
                        color: rgb(246, 28, 77);
                     }
                  }
               }
            }
   
            //end row
            .btnWrapper {
               width: 70%;
               margin: 2rem auto;
               display: flex;
               flex-direction: row; //row|row-reverse|column|column-reverse
               justify-content: space-evenly; //flex-start|flex-end|center|space-between|space-around|space-evenly|
               align-items: center; //stretch|center|flex-start|flex-end
               flex-wrap: nowrap; //|wrap|wrap-reverse
            }
         }
      }
   }
}
