/* Roboto */
@font-face {
    font-family: "Roboto-Regular";
    src: url("./../fonts/roboto/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Roboto-Bold";
    src: url("./../fonts/roboto/Roboto-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Roboto-Black";
    src: url("./../fonts/roboto/Roboto-Black.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-Italic";
    src: url("./../fonts/roboto/Roboto-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

/* Optional: Define default global font styles */
body {
    font-family: "Roboto-Regular", sans-serif;
}
