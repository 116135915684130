// Neutral Colors
$color-black: #000000;
$color-dark-gray: #333333;
$color-gray: #666666;
$color-light-gray: #999999;
$color-silver: #C0C0C0;
$color-white: #FFFFFF;

// Primary Colors
$color-red: #FF0000;
$color-green: #00FF00;
$color-blue: #0000FF;

// Secondary Colors
$color-yellow: #FFFF00;
$color-cyan: #00FFFF;
$color-magenta: #FF00FF;

// Commonly Used Colors
$color-dark-red: #8B0000;
$color-firebrick: #B22222;
$color-tomato: #FF6347;
$color-orange: #FFA500;
$color-dark-orange: #FF8C00;
$color-gold: #FFD700;
$color-olive: #808000;
$color-purple: #800080;
$color-violet: #EE82EE;
$color-indigo: #4B0082;
$color-steel-blue: #4682B4;
$color-dodger-blue: #1E90FF;
$color-medium-blue: #0000CD;
$color-light-blue: #ADD8E6;
$color-dark-green: #006400;
$color-lime: #00FF00;
$color-sea-green: #2E8B57;

// more
$color-red-ruby: rgb(246, 28, 77);
$color-orange-whitehouse: #edbe87;
$color-green-banana: rgb(87, 185, 63);
$color-warning: #ffc107;
$color-primary: #0d6efd;