@import './../../assets/styles/variables.module';
@import './../../assets/styles/palette.module';


@import './../../assets/styles/variables.module';
@import './../../assets/styles/palette.module';
// khi width screen trên 300px thì áp dụng css này
@media (min-width: 300px) {
    .Catalog{
        min-height: 67vh;
        width: 100%;
        padding: 0 0 2rem 0;
        .title {
            font-size: $font-size-heading;
            font-weight: bold;
            text-align: center;
            margin: 3rem 0 2rem 0;
        }
        .catalogList {
            display: grid;
            grid-template-columns: 1fr 1fr; 
            gap: 1rem; // add spacing between the items
            justify-items: center; // center the items horizontally within each grid cell
            padding: 0 0 0 0;
            margin: 0 0 0 0;
            width: 90%;
            margin: 0 auto;
            .catalogItem {
                padding: 1rem;
                margin: 0;
                width: 90%;
                height: 100%;
                box-shadow: 0 0 10px rgba(149, 149, 149, 0.893);
                border-radius: 0.5rem;
                cursor: pointer;
                text-align: center;
                overflow: hidden; // Ensure content doesn't overflow outside of the item
                transition: box-shadow 0.3s ease;
    
                &:hover {
                    box-shadow: 0 0 15px rgba(255, 19, 224, 0.893);
                }
                img {
                    width: 90%;
                    height: 75%;
                    transition: transform 1s ease; // Add smooth transition for scaling
        
                    &:hover {
                        transform: scale(1.05); // Scale the image up on hover
                        border-radius: 0.5rem;
                    }
                }
        
                .catalogName {
                    padding: 0.5rem 1rem;
                }
            }
        }
    }
}

@media (min-width: 500px) {
    .Catalog{
        .catalogList {
            grid-template-columns: 1fr 1fr;
            .catalogItem {
                width: 90%;
                height: 100%;
                img {
                    width: 90%;
                    height: 85%;
                }
            }
        }
    }
}
@media (min-width: 700px) {
    .Catalog{
        .catalogList {
            grid-template-columns: 1fr 1fr;
            .catalogItem {
                width: 90%;
                height: 100%;
                img {
                    width: 90%;
                    height: 90%;
                }
            }
        }
    }
}
@media (min-width: 1000px) {
    .Catalog{
        .catalogList {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}
@media (min-width: 1300px) {
    .Catalog{
        .catalogList {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
    
}
