@import './../../assets/styles/variables.module';
@import './../../assets/styles/palette.module';
// khi width screen trên 2200px thì áp dụng css này
@media (min-width: 300px) {
    .Product {
        width: 100%;
        min-height: 90vh;
        margin: 0 auto;
        .breadcums {
            display: flex;
            flex-direction: row; //row|row-reverse|column|column-reverse
            justify-content: flex-start; //flex-start|flex-end|center|space-between|space-around|space-evenly|
            align-items: center; //stretch|center|flex-start|flex-end
            flex-wrap: wrap; //|wrap|wrap-reverse
            padding: 0.5rem 1rem;
            // border: 1px solid red;
            p {
                margin: 0 0.5rem 0 0;
                cursor: pointer;
                // border: 1px solid blue;
                &:hover {
                   color: #0d6efd;
                   font-weight: bold;
                }
            }
            .arrow {
                color: $color-red-ruby;
                font-weight: bold;
            }
        }
        .structure {
            height: 30rem;
            width: 100%;
            margin: 0 0;
            display: flex;
            justify-content: center;
            align-items: flex-end; /* Align content to the bottom */
            background-size: cover;
            .bgColor {
                width: 100%;
                .sample {
                    height: 30rem;
                    width: 100%;
                    padding: 2rem 0.5rem 0 0.5rem;
                    color: white;
                    text-shadow: -1px -1px 0 #000,  1px -1px 0 #000,-1px  1px 0 #000,1px  1px 0 #000;
                
                    .title {
                        text-align: center;
                        font-size: $font-size-heading;
                        font-weight: bold;
                        margin: 0 0 5rem 0;
        
                    }
                    .intro {
                        text-align: center;
                        width: 80%;
                        margin: 0 auto;
                        letter-spacing: 0.2rem;
                        font-size: $font-size-paragraph;
                    }
         
                    
                }
            }
        }
        .productContainer {
            padding: 0 0.5rem;
            margin: 1rem 0;
            .title {
                font-size: $font-size-heading;
                font-weight: bold;
                margin: 0.5rem 0 0.5rem 1rem;
            }
            .productList {
                margin: 1rem 0 0 0;
                display: grid;
                grid-template-columns: 1fr;
                justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
                align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
                align-items: top;//top, center, end
                align-self: center;
                text-align: center;
                .product {
                    box-shadow: 0 0 10px rgba(192, 190, 190, 0.893);
                    border-radius: 0.5rem;
                    padding: 1rem;
                    margin: 1rem;
                    z-index: 3; // product
                    .productImage {
                        width: 100%;
                        // height: 20rem;
                        background-size: cover;
                        background-position: center;
                        // border: 1px solid blue;
                        padding: 0 0 0 0;
                        margin: 0 0 0 0;
                        img {
                            width: 100%; /* Make the image width 100% of the container */
                            height: 300px; /* Set a fixed height for all images */
                            object-fit: cover; /* Ensures the image covers the area while maintaining aspect ratio */
                            margin: 0 auto;
                            border-radius: 0.5rem;
                            border: 0.1 solid white;
                            box-shadow: 0 0 10px inset rgba(192, 190, 190, 0.893);
                        }
                    }
                    .productInfo {
                        padding: 0rem 0;
                        // border: 1px solid red;
                        margin: 1rem 0 0 0;
                        .productName {
                            font-size: $font-size-heading;
                            font-weight: bold;
                            margin: 0rem 0 1rem 0;
                        }
                        .productDesc {
                            font-size: $font-size-small;
                            span {
                                font-size: $font-size-small;
                                cursor: pointer;
                                background: none;
                                border: none;
                                outline: none;
                                color: blue;
                                margin: 0 0 0 0.5rem;
                            }
                        }
                        .view {
                            text-align: center;
                            width: fit-content;
                            margin: 0.5rem auto 0 auto;
                            padding: 0.5rem 1rem;
                            // letter-spacing: 0.2rem;
                            border: 1px solid rgb(231, 231, 231);
                            color: black;
                            border-radius: 0.5rem;
                            background-color: transparent;
                            transition: all 0.2s ease; /* Smooth transition for all properties */
                            cursor: pointer; /* Pointer cursor to indicate interactivity */
                            &:hover {
                              box-shadow: 0 0 10px rgba(206, 206, 206, 0.893);
                            }
                            &:active{
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 500px){
    .Product{
        .structure {
            .bgColor {
                .sample {
                    width: 100%;
                    p:nth-child(2) {
                        display:block;
                        min-width: 80%;
                        margin: 0 auto;
                        word-break: break-word; /* Allows long words to be broken and wrapped */
                        overflow-wrap: break-word; /* Ensures text breaks to prevent overflow */
                        line-height: 1.5; /* Optional: Adjust line height for better readability */
                        font-size: $font-size-paragraph;
                    }
                }
            }
        }
    }
}
@media (min-width: 700px) {
    .Product{
        .rowIcon {
            display: flex;
        }
        .structure {
            .bgColor {
                .sample {
                    padding: 5rem 0 0 0;
                    width: 100%;
                    p:nth-child(2) {
                        display:block;
                        min-width: 70%;
                        margin: 0 auto;
                        word-break: break-word; /* Allows long words to be broken and wrapped */
                        overflow-wrap: break-word; /* Ensures text breaks to prevent overflow */
                        line-height: 1.5; /* Optional: Adjust line height for better readability */
                        font-size: $font-size-paragraph;

                    }
                }
            }
        }
    }
}
@media (min-width: 1000px) {
    .Product{
        .structure {
            .bgColor {
                .sample {
                    padding: 5rem 0 0 0;
                    width: 100%;
                    p:nth-child(2) {
                        font-size: $font-size-subheading;
                    }
                }
            }
        }
        .productContainer {
            .productList {
                display: grid;
                grid-template-columns: 1fr 1fr;
                justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
                align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
                align-items: top;//top, center, end
                align-self: center;
                text-align: center;
                .product {
                    .productInfo {
                        padding: 0 0 0 0;
                        margin: 1rem 0 0 0;
                
                    }
                }
            }
        }
    }
}
@media (min-width: 1300px) {
    .Product{
        .productContainer {
            .productList {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                justify-content: space-between; //flex-start|flex-end|center|space-between|space-around|space-evenly|initial|inherit
                align-content: center; // start || center || end || stretch || space-around || space-between || space-evenly
                align-items: top;//top, center, end
                align-self: center;
                text-align: center;
                .product {
                    .productInfo {
                        padding: 0 0 0 0;
                        margin: 1rem 0 0 0;
                
                    }
                }
            }
        }
    }
}